import { __assign, __spreadArray } from "tslib";
import * as React from 'react';
import classNames from 'classnames';
import { useSize } from 'ahooks';
import { useCnRequest, useFormLabelAlign, nativePropsReg, } from '@cainiaofe/cn-ui-common';
import { formilyReact, formilyCore } from '@/form/formily';
import { createScope } from './components/schema-field';
import { CnFormLayout } from '@/form/cn-form-layout';
import { CnIcon } from '@/components/cn-icon';
import { responsiveSizeH, responsiveSizeV } from './const';
import { FilterSelectedTags } from './components/filter-selected-tags';
import { ConfigModal } from './components/save-config';
import { SaveSelector, InputSaveOverlay } from './components/save-selected';
import { CnFilterGrid } from './components/cn-filter-grid';
import { CnFilterFlex } from './components/cn-filter-flex';
import { FilterItemBtns } from './components/filter-item-btns';
import { JSXField } from './components/jsx-field';
import { CnFilterProItem } from './components/cn-filter-pro-item';
import { useSaveSelected } from './hooks/use-save-selected';
import { useSaveConfig } from './hooks/use-save-config';
import { isInvisible, calcLayout, calcLayoutWithButton, calcFlexLayout, getMaxRowBySchema, } from './utils';
import { FilterRefsContext, FilterPropsContext } from './context';
import './index.scss';
import { SaveSelectedActions } from './store';
import { setCache, removeCache } from './cache';
import { HocBaseComponents, Plugin, pluginManager, safeCallFunction, } from '@/components/cn-utils';
import debounce from 'lodash/debounce';
import { obj } from '@fusion/lib/util';
import { getRenderItems } from './components/filter-selected-tags/get-render-items';
var createForm = formilyCore.createForm, onFormInputChange = formilyCore.onFormInputChange, onFieldChange = formilyCore.onFieldChange;
var FormProvider = formilyReact.FormProvider, ExpressionScope = formilyReact.ExpressionScope;
export var CnFilterPro = React.forwardRef(function (props, ref) {
    var _a;
    var style = props.style, className = props.className, form = props.form, formProps = props.formProps, components = props.components, propsSchema = props.schema, scope = props.scope, children = props.children, $i18n = props.$i18n, extendButtons = props.extendButtons, columns = props.columns, responsiveSize = props.responsiveSize, columnGap = props.columnGap, rowGap = props.rowGap, colon = props.colon, onColumnChange = props.onColumnChange, enableExpand = props.enableExpand, maxVisibleRow = props.maxVisibleRow, defaultExpand = props.defaultExpand, propsExpand = props.expand, onExpandChange = props.onExpandChange, showSelected = props.showSelected, showFolder = props.showFolder, showBottomLine = props.showBottomLine, storageKey = props.storageKey, onGetStorage = props.onGetStorage, onSetStorage = props.onSetStorage, enableConfig = props.enableConfig, defaultConfigValue = props.defaultConfigValue, configValue = props.configValue, propsOnConfigValueChange = props.onConfigValueChange, enableSaveSelected = props.enableSaveSelected, saveSelectSpan = props.saveSelectSpan, beforeGetSaveSelected = props.beforeGetSaveSelected, onChange = props.onChange, onSearch = props.onSearch, onSubmit = props.onSubmit, onReset = props.onReset, labelAlign = props.labelAlign, labelTextAlign = props.labelTextAlign, size = props.size, rtl = props.rtl, labelCol = props.labelCol, wrapperCol = props.wrapperCol, requestConfig = props.requestConfig, cacheSearch = props.cacheSearch, isRightButton = props.isRightButton, buttonSpan = props.buttonSpan, hideButton = props.hideButton, removeEmptyLabel = props.removeEmptyLabel, filterDisplay = props.filterDisplay;
    var _b = React.useState(1), forceUpdate = _b[1];
    var _c = React.useState(1), recalculate = _c[0], setRecalculate = _c[1];
    var _d = React.useState(false), fold = _d[0], setFold = _d[1];
    var filterRef = React.useRef();
    var resizeRef = React.useRef();
    var selectedCacheRef = React.useRef(new Map());
    var filterPopupContainerRef = React.useRef();
    var saveSelectedStateRef = React.useRef();
    var expandClickRef = React.useRef(false);
    React.useImperativeHandle(ref, function () { return resizeRef.current; }, [resizeRef.current]);
    var RealSchemaField = React.useMemo(function () { return createScope(scope); }, [scope]);
    var _e = React.useState(), formInstance = _e[0], setFormInstance = _e[1];
    // schema变化前的form values
    var formInstanceValuesRef = React.useRef();
    var _f = React.useState(defaultExpand || false), expand = _f[0], setExpand = _f[1];
    var defaultValuesRef = React.useRef((formInstance && formInstance.getState().values) || {});
    var searchValuesRef = React.useRef((formInstance && formInstance.getState().values) || {});
    var innerValues = formInstance && formInstance.getState().values;
    var _g = React.useState(propsSchema || {}), schema = _g[0], setSchema = _g[1];
    var _h = React.useState(), schemaChanged = _h[0], setSchemaChanged = _h[1];
    React.useEffect(function () {
        if (propsSchema) {
            setSchema(propsSchema);
        }
    }, [propsSchema]);
    React.useEffect(function () {
        if (propsExpand !== undefined && propsExpand !== expand) {
            setExpand(propsExpand);
        }
    }, [expand, propsExpand]);
    var handleToggleMore = React.useCallback(function () {
        expandClickRef.current = true;
        onExpandChange && onExpandChange(!expand);
        if (propsExpand === undefined) {
            setExpand(!expand);
        }
    }, [propsExpand, expand, onExpandChange]);
    var width = (useSize(resizeRef) || {}).width;
    // 实际的标签对齐方式，解决自动场景
    var realLabelAlign = useFormLabelAlign(labelAlign);
    var innerColumns = React.useMemo(function () {
        if (columns)
            return columns;
        var _responsive = responsiveSize ||
            (realLabelAlign === 'left' ? responsiveSizeH : responsiveSizeV);
        var matched = _responsive.length; // 默认8个
        if (!width)
            return matched;
        _responsive.some(function (v, idx) {
            if (width < v) {
                matched = idx;
                return true;
            }
            return false;
        });
        // 最小值2
        return matched > 2 ? matched : 2;
    }, [width, schema]);
    React.useEffect(function () {
        onColumnChange && onColumnChange(Number(innerColumns));
    }, [innerColumns, onColumnChange]);
    var handleSearch = function () {
        formInstance.validate().then(function () {
            searchValuesRef.current = formInstance.getState().values;
            setCache(searchValuesRef.current, storageKey, cacheSearch);
            onSearch && onSearch(formInstance.getState().values);
            // searchValuesRef更新了, 需要触发tags渲染
            forceUpdate(function (s) { return (s + 1) % 32; });
        }, function (error) {
            console.log(error);
        });
    };
    var handleSubmit = function (e) {
        e && e.preventDefault();
        formInstance.submit(onSubmit);
        handleSearch();
    };
    var handleReset = function () {
        formInstance && formInstance.reset('*');
        formInstance &&
            (defaultValuesRef === null || defaultValuesRef === void 0 ? void 0 : defaultValuesRef.current) &&
            formInstance.setValues(defaultValuesRef === null || defaultValuesRef === void 0 ? void 0 : defaultValuesRef.current);
        searchValuesRef.current = formInstance.getState().values;
        forceUpdate(function (s) { return (s + 1) % 32; });
        if (onReset) {
            return onReset();
        }
        handleChange(formInstance.getState().values);
        handleSearch();
    };
    var handleChange = function (values) {
        onChange && onChange(values);
    };
    var handleRemove = function (key) {
        formInstance && formInstance.setValuesIn(key, undefined);
        searchValuesRef.current = formInstance.getState().values;
        forceUpdate(function (s) { return (s + 1) % 32; });
        handleChange(formInstance.getState().values);
        handleSearch();
    };
    var getSearchValues = function () { return searchValuesRef.current; };
    var filterRefContext = React.useRef({
        update: function () { return undefined; },
        itemCollection: {},
        filterRef: filterRef,
        overlayRef: React.useRef(null),
        currentSelectedValuesRef: React.useRef(null),
        formInstance: {},
        refreshed: false,
    });
    if (formInstance) {
        formInstance.filterSubmit = handleSubmit;
        formInstance.filterReset = handleReset;
        formInstance.filterSearch = handleSearch;
        formInstance.filterChange = handleChange;
        formInstance.getSearchValues = getSearchValues;
    }
    var initialChildren = React.useMemo(function () {
        if (!schema || !schema.properties)
            return [];
        return Object.keys(schema.properties).map(function (v) { return ({
            key: v,
            value: __assign({}, schema.properties[v]),
        }); });
    }, [schema]);
    var _j = useSaveConfig({
        enableConfig: enableConfig,
        defaultConfigValue: defaultConfigValue,
        configValue: configValue,
        storageKey: storageKey,
        children: initialChildren,
        onGetStorage: onGetStorage,
        onSetStorage: onSetStorage,
    }), saveConfigState = _j[0], saveConfigDispatch = _j[1];
    var _k = useSaveSelected({
        enableSaveSelected: enableSaveSelected,
        values: searchValuesRef.current,
        storageKey: storageKey,
        onGetStorage: onGetStorage,
        onSetStorage: onSetStorage,
        filterContext: filterRefContext.current,
    }), saveSelectedState = _k[0], saveSelectedDispatch = _k[1];
    var arrangedChildren = saveConfigState.arrangedChildren;
    var showAll = !enableExpand || expand;
    var isFlex = React.useMemo(function () {
        return filterDisplay === 'flex';
    }, [filterDisplay]);
    // 计算展示和隐藏元素
    var _l = React.useMemo(function () {
        var maxRow = getMaxRowBySchema(schema, enableSaveSelected);
        if (filterDisplay === 'flex') {
            return calcFlexLayout(showAll ? maxRow : Number(maxVisibleRow) || 2, width, arrangedChildren, enableSaveSelected, filterRefContext, columnGap || 16, size, hideButton);
        }
        return isRightButton
            ? calcLayoutWithButton(showAll ? maxRow : Number(maxVisibleRow) || 2, Number(innerColumns), Number(buttonSpan) || 1, arrangedChildren, enableSaveSelected, saveSelectSpan, filterRefContext)
            : calcLayout(showAll ? maxRow : Number(maxVisibleRow) || 2, Number(innerColumns), arrangedChildren, enableSaveSelected, saveSelectSpan, filterRefContext);
    }, [
        showAll,
        innerColumns,
        maxVisibleRow,
        schema,
        arrangedChildren,
        enableSaveSelected,
        saveSelectSpan,
        filterDisplay,
        width,
        hideButton,
        recalculate,
        // _, //用于修正搜索时候导致屏幕闪动的问题，其他影响未知，需要发beta版本测试
    ]), showChildren = _l.showChildren, hideChildren = _l.hideChildren;
    var filterPropsContext = React.useMemo(function () {
        return __assign(__assign({}, props), { cols: (props.columns || innerColumns), showAll: showAll, expand: expand, hideChildren: hideChildren, handleToggleMore: handleToggleMore, saveSelectedState: saveSelectedState, saveSelectedDispatch: saveSelectedDispatch, saveConfigState: saveConfigState, saveConfigDispatch: saveConfigDispatch, rowGap: rowGap, columnGap: columnGap, rtl: rtl });
    }, [
        props,
        showAll,
        expand,
        hideChildren,
        handleToggleMore,
        innerColumns,
        saveSelectedState,
        saveSelectedDispatch,
        saveConfigState,
        saveConfigDispatch,
        rowGap,
        columnGap,
        rtl,
    ]);
    var realSchema = React.useMemo(function () {
        var properties = {};
        __spreadArray(__spreadArray([], showChildren, true), hideChildren.map(function (child) { return (__assign(__assign({}, child), { value: __assign(__assign({}, child.value), { isFold: true }) })); }), true).forEach(function (_a, idx) {
            var key = _a.key, value = _a.value;
            properties[key] = __assign(__assign({}, value), { 'x-index': idx + 1 });
        });
        return {
            type: 'object',
            properties: properties,
        };
    }, [showChildren, hideChildren]);
    var applyValues = function (f, hiddenKeys, mode) {
        if (mode === void 0) { mode = 'merge'; }
        if (!formInstanceValuesRef.current)
            return;
        // 布局更新（屏幕尺寸调整/查询项调整）时，重新应用上次的 form values
        Object.keys(formInstanceValuesRef.current).forEach(function (k) {
            if (hiddenKeys.includes(k)) {
                delete formInstanceValuesRef.current[k];
            }
        });
        f.setValues(formInstanceValuesRef.current, mode);
    };
    var handleConfigValueChange = function (value) {
        formInstance &&
            applyValues(formInstance, value
                .map(function (_a) {
                var visible = _a.visible, name = _a.name;
                if (!visible)
                    return name;
                return undefined;
            })
                .filter(Boolean), 'overwrite');
        searchValuesRef.current = formInstance.getState().values;
        forceUpdate(function (s) { return (s + 1) % 32; });
        handleChange(formInstance.getState().values);
        safeCallFunction(propsOnConfigValueChange, value);
    };
    React.useEffect(function () {
        if (!cacheSearch) {
            // 关闭时 清空一次session值
            removeCache(storageKey);
        }
    }, [cacheSearch]);
    React.useEffect(function () {
        // 刷新form要在realSchema更新后的nexttick中，否则不能生效
        setSchemaChanged({});
    }, [form, realSchema]);
    React.useEffect(function () {
        // 记住上次 form values
        if (formInstance && formInstance.getState) {
            formInstanceValuesRef.current = formInstance.getState().values;
        }
        var newForm = null;
        if (form) {
            newForm = form;
            searchValuesRef.current = form.getState().values;
            // 解决联动造成的布局错乱问题
            newForm.addEffects('onFieldReactChangeByHidden', function () {
                onFieldChange('*', ['hidden'], function () {
                    setRecalculate(recalculate + 1);
                });
            });
        }
        else {
            newForm = createForm(__assign({}, formProps));
        }
        // 需要根据处理动态显隐的 field
        var filterGrid = newForm.query('filterGrid').take();
        if (filterGrid && !filterRefContext.current.refreshed) {
            filterRefContext.current.refreshed = true;
            expandClickRef.current = true;
            forceUpdate(function (s) { return (s + 1) % 32; });
        }
        else {
            setFormInstance(newForm);
        }
        // setFormInstance(newForm);
    }, [schemaChanged]);
    React.useEffect(function () {
        if (!formInstance || !formInstanceValuesRef.current)
            return;
        filterRefContext.current.formInstance = formInstance;
        applyValues(formInstance, arrangedChildren
            .map(function (_a) {
            var key = _a.key, value = _a.value;
            if (isInvisible(value))
                return key;
            return undefined;
        })
            .filter(Boolean));
    }, [formInstance]);
    React.useEffect(function () {
        // 查询项有变化时 触发onchange 并 清空查询习惯
        saveSelectedStateRef.current = saveSelectedState;
        formInstance === null || formInstance === void 0 ? void 0 : formInstance.addEffects('onFormInputChange', function () {
            onFormInputChange(function (f) {
                handleChange(f.getState().values);
                saveSelectedDispatch(SaveSelectedActions.clearSelected(saveSelectedStateRef.current, {
                    values: f.getState().values,
                }));
            });
        });
    }, [formInstance, saveSelectedState]);
    var debounceCnFilterOnChange = React.useMemo(function () {
        return debounce(function () {
            var _a;
            (_a = formInstance === null || formInstance === void 0 ? void 0 : formInstance.filterSearch) === null || _a === void 0 ? void 0 : _a.call(formInstance);
        }, 500, {
            leading: false,
            trailing: true,
        });
    }, [formInstance]);
    React.useEffect(function () {
        if (hideButton === true) {
            formInstance === null || formInstance === void 0 ? void 0 : formInstance.addEffects('searchAfterChange', function () {
                onFormInputChange(function () {
                    debounceCnFilterOnChange();
                });
            });
        }
        else {
            formInstance === null || formInstance === void 0 ? void 0 : formInstance.removeEffects('searchAfterChange');
        }
    }, [hideButton, formInstance]);
    var isInitRemote = React.useMemo(function () {
        return !!((requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.url) || (requestConfig === null || requestConfig === void 0 ? void 0 : requestConfig.service));
    }, [requestConfig]);
    var readyRequestConfig = React.useMemo(function () { return (__assign(__assign({}, requestConfig), { ready: isInitRemote })); }, [requestConfig, isInitRemote]);
    React.useImperativeHandle(ref, function () {
        return __assign(__assign({}, (resizeRef.current || {})), { getContainerRef: function () {
                return resizeRef.current;
            }, getFilterRef: function () {
                return filterRef.current;
            }, getForm: function () {
                return formInstance;
            }, getSchema: function () {
                return realSchema;
            }, getSelectedTagsItems: function () {
                var searchValues = searchValuesRef.current;
                var itemCollection = filterRefContext.current.itemCollection;
                return getRenderItems(searchValues, itemCollection, selectedCacheRef.current);
            } });
    }, [
        resizeRef,
        filterRef,
        formInstance,
        realSchema,
        searchValuesRef,
        filterRefContext,
        selectedCacheRef,
    ]);
    var _m = useCnRequest(readyRequestConfig), data = _m.data, loading = _m.loading;
    React.useEffect(function () {
        if (!isInitRemote)
            return;
        if (loading)
            return;
        formInstance === null || formInstance === void 0 ? void 0 : formInstance.setValues(data || {});
        handleChange(formInstance === null || formInstance === void 0 ? void 0 : formInstance.getState().values);
    }, [data]);
    // 渲染 JSON Schema
    var renderFilterSchema = function () {
        if (!width || !realSchema || !realSchema.properties)
            return null;
        return (React.createElement(RealSchemaField, { scope: scope, components: components },
            React.createElement(RealSchemaField.Void, { name: "filterGrid", "x-component-props": {
                    realSchema: realSchema,
                }, "x-component": isFlex ? CnFilterFlex : CnFilterGrid },
                enableSaveSelected && (React.createElement(RealSchemaField.Void, { name: "saveSelector", "x-component": SaveSelector, "x-decorator-props": {
                        colSpan: saveSelectSpan,
                    }, "x-index": 0 })),
                Object.keys(realSchema.properties).map(function (n) {
                    return (React.createElement(RealSchemaField.Markup, __assign({ name: n }, realSchema.properties[n])));
                }),
                !hideButton && (isRightButton || isFlex) && (React.createElement(RealSchemaField.Void, { name: "rightButton", "x-component": FilterItemBtns, "x-component-props": { hideButton: hideButton }, "x-decorator": CnFilterProItem, "x-decorator-props": isFlex
                        ? {
                            label: false,
                            wrapperWidth: extendButtons ? 280 : 180,
                            className: 'cn-ui-filter-pro-right-button',
                        }
                        : {
                            colSpan: buttonSpan,
                            className: 'cn-ui-filter-pro-right-button',
                        } })))));
    };
    if (!formInstance) {
        return React.createElement("div", null, "Form initializing instance...");
    }
    var hasFoldLine = (showFolder && !isFlex) || showBottomLine;
    // props处理
    var pickedNativeProps = obj.pickAttrsWith(props, nativePropsReg);
    return (React.createElement(FormProvider, { form: formInstance },
        React.createElement(ExpressionScope, { value: { $$form: formInstance } },
            React.createElement(FilterRefsContext.Provider, { value: filterRefContext.current },
                React.createElement(FilterPropsContext.Provider, { value: filterPropsContext },
                    React.createElement("div", __assign({ "data-name": "CnFilterPro", 
                        // id="filter-popup-container"
                        className: classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-filter-pro', className, (_a = {
                                'cn-ui-filter-pro--foldline': hasFoldLine
                            },
                            _a["cn-ui-filter-pro-".concat(size)] = size,
                            _a)), style: style, ref: resizeRef, onKeyDown: function (e) {
                            var _a, _b;
                            if (((_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.tagName) === null || _b === void 0 ? void 0 : _b.toLocaleUpperCase()) === 'INPUT' &&
                                ((e === null || e === void 0 ? void 0 : e.key) === 'Enter' ||
                                    ((e === null || e === void 0 ? void 0 : e.keyCode) || (e === null || e === void 0 ? void 0 : e.which) || (e === null || e === void 0 ? void 0 : e.charCode)) === 13)) {
                                handleSubmit(e);
                            }
                        } }, pickedNativeProps),
                        React.createElement(ConfigModal, { enableConfig: enableConfig, enableSaveSelected: enableSaveSelected, onConfigValueChange: handleConfigValueChange, configValue: configValue, store: saveConfigState, dispatch: saveConfigDispatch, gridProps: {
                                columns: innerColumns,
                                gap: [8, 8],
                            }, saveSelectSpan: saveSelectSpan }),
                        React.createElement(InputSaveOverlay, { enableSaveSelected: enableSaveSelected, values: searchValuesRef.current, beforeGetSaveSelected: beforeGetSaveSelected, store: saveSelectedState, dispatch: saveSelectedDispatch }),
                        React.createElement("div", { style: fold ? { display: 'none' } : undefined },
                            React.createElement(CnFormLayout, __assign({}, {
                                colon: colon,
                                // 流式布局标题强制左右结构
                                labelAlign: isFlex ? 'left' : labelAlign,
                                labelTextAlign: labelTextAlign,
                                labelCol: labelCol,
                                wrapperCol: wrapperCol,
                                size: size,
                                // 解决流式布局样式问题
                                removeEmptyLabel: isFlex ? true : removeEmptyLabel,
                                filterDisplay: filterDisplay,
                            }),
                                renderFilterSchema(),
                                React.createElement(JSXField, __assign({}, {
                                    propsSchema: propsSchema,
                                    schema: schema,
                                    setSchema: setSchema,
                                    children: children,
                                    formInstance: formInstance,
                                }))),
                            isRightButton || isFlex ? null : (React.createElement(FilterItemBtns, { hideButton: hideButton }))),
                        showSelected && !isFlex ? (React.createElement(FilterSelectedTags, { values: searchValuesRef.current, innerValues: innerValues, onRemove: handleRemove })) : null,
                        hasFoldLine ? (React.createElement("div", { className: "cn-ui-filter-foldline" }, showFolder && !isFlex ? (React.createElement("div", { className: "cn-ui-filter-foldline-btn", "data-hottag": fold
                                ? 'cn-ui.cn-filter-pro.expand'
                                : 'cn-ui.cn-filter-pro.collapse', onClick: function () {
                                setFold(!fold);
                            } },
                            React.createElement(CnIcon, { className: "cn-ui-filter-foldline-btn-icon", type: fold ? 'icon-arrow-down' : 'icon-arrow-up', size: "small" }),
                            fold
                                ? $i18n.get({
                                    id: 'Expand',
                                    dm: '展开',
                                    ns: 'CnFilter',
                                })
                                : $i18n.get({
                                    id: 'PutItAway',
                                    dm: '收起',
                                    ns: 'CnFilter',
                                }))) : null)) : null,
                        React.createElement("div", { "data-role": "filter-popup-container", ref: filterPopupContainerRef })))))));
});
export var CnFilterProPlugin = React.forwardRef(function (props, ref) {
    var plugin = React.useMemo(function () {
        var _plugin = new Plugin('CnFilterPro', pluginManager);
        _plugin.setLocalPlugin(props === null || props === void 0 ? void 0 : props.usePlugin);
        _plugin.setGlobalPlugin(pluginManager
            .getPluginsByComponentName('CnFilterPro')
            .map(function (item) { return item.method; }));
        return _plugin;
    }, []);
    var plugins = plugin.getPlugin();
    if (plugins.length === 0) {
        return React.createElement(CnFilterPro, __assign({}, props, { ref: ref }));
    }
    return HocBaseComponents(CnFilterPro, { props: props, plugins: plugins, ref: ref });
});
CnFilterPro.defaultProps = {
    extendButtonsPosition: 'end',
    expandButtonUseIcon: false,
    resetButtonUseIcon: false,
    isRightButton: false,
    buttonSpan: 1,
    enableExpand: true,
    maxVisibleRow: 2,
    showSelected: false,
    showFolder: true,
    showBottomLine: false,
    enableConfig: false,
    enableSaveSelected: false,
    saveSelectSpan: 1,
    useLabelForErrorMessage: true,
    filterDisplay: 'grid',
};
CnFilterPro.displayName = 'CnFilterPro';
