export var findFirstLevelActivatedByThirdLevelActiveKey = function (menu, selectedKey) {
    var findResult;
    menu.forEach(function (menuItem) {
        if (menuItem.key === selectedKey) {
            findResult = menuItem.key;
        }
        if (Array.isArray(menuItem.children)) {
            menuItem.children.forEach(function (subItem) {
                if (Array.isArray(subItem.children)) {
                    subItem.children.forEach(function (thirdItem) {
                        if (thirdItem.key === selectedKey) {
                            findResult = menuItem.key;
                        }
                    });
                }
            });
        }
    });
    return findResult;
};
export var handleSetTopKey = function (_arr, _parentKey, _topKey) {
    if (_arr === null || _arr === void 0 ? void 0 : _arr.length) {
        _arr.forEach(function (i) {
            var _a;
            i._parentKey = _parentKey;
            i._topKey = _topKey;
            if (i.children && ((_a = i.children) === null || _a === void 0 ? void 0 : _a.length)) {
                handleSetTopKey(i.children, i.key, _topKey);
            }
        });
    }
    return _arr;
};
