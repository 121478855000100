import { __assign } from "tslib";
import React, { forwardRef, useEffect, useRef, useImperativeHandle, useState, } from 'react';
import classNames from 'classnames';
import { CnListProItem } from './cn-list-pro-item';
import isFunction from 'lodash/isFunction';
import { CnListProContext } from '@/components/cn-list-pro/hooks';
import { CnGrid } from '@/components/cn-grid';
import { ResponsiveGrid } from '@/components/fusion';
import { CnPagination } from '@/components/cn-pagination';
import { CnResult } from '@/components/cn-result';
import { CnLoading } from '@/components/cn-loading';
import isObject from 'lodash/isObject';
import './cn-list-pro.scss';
import { getColumnProps } from '../utils';
import { useSize } from 'ahooks';
import { getCnListProResponsiveGridColumns } from '@cainiaofe/cn-ui-common';
var Cell = ResponsiveGrid.Cell;
var defaultPagination = {
    pageSizeSelector: 'dropdown',
    pageSizePosition: 'start',
    align: 'right',
};
export var CnListPro = forwardRef(function (props, ref) {
    var className = props.className, style = props.style, renderItem = props.renderItem, dataSource = props.dataSource, children = props.children, rowSelection = props.rowSelection, itemKey = props.itemKey, grid = props.grid, pagination = props.pagination, loading = props.loading, shape = props.shape, imagePosition = props.imagePosition, header = props.header, footer = props.footer;
    var listRef = useRef(null);
    var listItemsRef = useRef(null);
    var cachePreviousElement = useRef(null);
    useImperativeHandle(ref, function () { return listRef.current; }, [listRef]);
    // 监听item的鼠标进入事件
    var handleMouseOver = function (event) {
        var index = Array.from(listItemsRef.current).indexOf(event.currentTarget);
        if (index > 0 &&
            !listItemsRef.current[index - 1].classList.contains('cn-ui-list-pro-item--selected')) {
            listItemsRef.current[index - 1].classList.add('cn-ui-list-pro-item--hover-previous');
        }
    };
    // 监听item的鼠标离开事件
    var handleMouseOut = function (event) {
        var index = Array.from(listItemsRef.current).indexOf(event.currentTarget);
        if (index > 0) {
            listItemsRef.current[index - 1].classList.remove('cn-ui-list-pro-item--hover-previous');
        }
    };
    // 监听item的点击事件
    var handleClick = function (event) {
        var index = Array.from(listItemsRef.current).indexOf(event.currentTarget);
        setTimeout(function () {
            if (cachePreviousElement.current &&
                cachePreviousElement.current !== listItemsRef.current[index - 1]) {
                cachePreviousElement.current.classList.remove('cn-ui-list-pro-item--selected-previous');
            }
            if (index > 0) {
                listItemsRef.current[index - 1].classList.add('cn-ui-list-pro-item--selected-previous');
                cachePreviousElement.current = listItemsRef.current[index - 1];
            }
        });
    };
    // 根据shape的不同，监听item的鼠标事件
    useEffect(function () {
        if (shape === 'card') {
            listItemsRef.current = listRef.current.querySelectorAll('.cn-ui-list-pro-item');
            listItemsRef.current.forEach(function (item) {
                item.addEventListener('mouseover', handleMouseOver);
                item.addEventListener('mouseout', handleMouseOut);
                if (isObject(rowSelection)) {
                    item.addEventListener('click', handleClick);
                }
            });
            return function () {
                listItemsRef.current.forEach(function (item) {
                    item.removeEventListener('mouseover', handleMouseOver);
                    item.removeEventListener('mouseout', handleMouseOut);
                    if (isObject(rowSelection)) {
                        item.removeEventListener('click', handleClick);
                    }
                });
            };
        }
    }, [dataSource]);
    // CnListPro最外层的className
    var classes = classNames(CN_UI_HASH_CLASS_NAME, 'cn-ui-list-pro', className);
    // 存储选中的卡片数据
    var _a = useState({
        selectedRowKeys: [],
        selectedRows: [],
    }), rowSelectionStore = _a[0], setRowSelectionStore = _a[1];
    /**
     * 根据配置获取列表的children
     * 当列表是通过 renderItem 自定义渲染时, 返回renderItem渲染dataSource的结果
     * 否则当dataSource非空时, 根据dataSource渲染CnListProItem
     * 否则当children非空时, 根据children渲染
     * @returns 列表的children
     */
    var getChildren = function () {
        if (isFunction(renderItem)) {
            return Array.isArray(dataSource)
                ? dataSource.map(function (item, index) {
                    return renderItem(item, index);
                })
                : null;
        }
        if (Array.isArray(dataSource) && dataSource.length > 0) {
            return dataSource.map(function (item) {
                return (React.createElement(CnListProItem, __assign({}, item, { key: item[itemKey], itemKey: item[itemKey], rowData: item, imagePosition: imagePosition })));
            });
        }
        // 循环 children，并把 key 传给 CnListProItem
        if (children) {
            return React.Children.map(children, function (child) {
                var _a;
                return React.cloneElement(child, {
                    itemKey: itemKey === 'key'
                        ? child.key
                        : (_a = child.props) === null || _a === void 0 ? void 0 : _a[itemKey],
                });
            });
        }
        return children;
    };
    var width = (useSize(listRef) || {}).width;
    /**
     * 根据响应式配置渲染列表
     * @returns 渲染的列表
     */
    var renderChildren = function () {
        var _children = getChildren();
        if (grid) {
            var columnProps_1 = getColumnProps(grid);
            if (shape === 'card' && (grid === null || grid === void 0 ? void 0 : grid.wrap)) {
                return (React.createElement(ResponsiveGrid, { gap: 16, columns: (grid === null || grid === void 0 ? void 0 : grid.column) || getCnListProResponsiveGridColumns(grid, width) || 1 }, React.Children.map(_children, function (child) {
                    return React.createElement(Cell, { colSpan: 1 }, child);
                })));
            }
            var gutter = shape === 'card' && !(grid === null || grid === void 0 ? void 0 : grid.wrap) ? 16 : grid.gutter;
            return (React.createElement(CnGrid.CnRow, { gutter: gutter, wrap: grid.wrap }, React.Children.map(_children, function (child) {
                return React.createElement(CnGrid.CnCol, __assign({}, columnProps_1), child);
            })));
        }
        if (!_children || (Array.isArray(_children) && _children.length === 0)) {
            return React.createElement(CnResult, { type: "empty" });
        }
        return _children;
    };
    return (React.createElement("div", { ref: listRef, className: classes, style: style },
        header,
        React.createElement(CnLoading, { visible: loading, style: { width: '100%' } },
            React.createElement(CnListProContext.Provider, { value: {
                    rowSelection: rowSelection,
                    itemKey: itemKey,
                    rowSelectionStore: rowSelectionStore,
                    setRowSelectionStore: setRowSelectionStore,
                    shape: shape,
                    grid: grid,
                    imagePosition: imagePosition,
                } }, renderChildren()),
            isObject(pagination) && (React.createElement("div", { className: "cn-ui-list-pro-pagination" },
                React.createElement(CnPagination, __assign({}, defaultPagination, pagination))))),
        footer));
});
CnListPro.displayName = 'CnListPro';
CnListPro.defaultProps = {
    itemKey: 'key',
    loading: false,
    shape: 'normal',
    imagePosition: 'left',
};
