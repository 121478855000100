import * as React from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { handleSetTopKey } from '../utils/full-header-menu';
export var SubPanel = function (innerProps) {
    var _dataSource = innerProps.dataSource, onSelect = innerProps.onSelect, setOpenKeys = innerProps.setOpenKeys, firstLevelActiveKey = innerProps.firstLevelActiveKey, selectedKeys = innerProps.selectedKeys, fullMenuItemIconRender = innerProps.fullMenuItemIconRender, hideExternalIcon = innerProps.hideExternalIcon, onExternalSelect = innerProps.onExternalSelect;
    var hasChildren = Array.isArray(_dataSource === null || _dataSource === void 0 ? void 0 : _dataSource.children);
    var dataSource = React.useMemo(function () {
        var _a;
        if ((_a = _dataSource === null || _dataSource === void 0 ? void 0 : _dataSource.children) === null || _a === void 0 ? void 0 : _a.length) {
            _dataSource.children = handleSetTopKey(_dataSource.children, _dataSource.key, _dataSource.key);
        }
        return _dataSource;
    }, [_dataSource]);
    return !hasChildren ? (React.createElement("div", { className: "cn-ui-shell-full-header-menu" })) : (React.createElement("div", { className: "cn-ui-shell-full-header-menu" }, dataSource.children.map(function (subMenuItem) {
        var _a, _b, _c;
        return (React.createElement("div", { className: "cn-ui-shell-full-header-menu-item", key: subMenuItem.key },
            ((_a = subMenuItem === null || subMenuItem === void 0 ? void 0 : subMenuItem.children) === null || _a === void 0 ? void 0 : _a.length) > 0 ? (React.createElement("div", { className: "cn-ui-shell-full-header-menu-item-header" }, subMenuItem.text)) : (React.createElement("div", { onClick: function () {
                    onSelect && onSelect([subMenuItem.key], subMenuItem);
                    setOpenKeys([]);
                }, className: classNames({
                    'cn-ui-shell-full-header-menu-item-header': true,
                    interact: true,
                    active: firstLevelActiveKey === subMenuItem.key,
                }) }, subMenuItem.text)),
            React.createElement("div", { className: "cn-ui-shell-full-header-menu-item-footer" }, (_c = (_b = subMenuItem === null || subMenuItem === void 0 ? void 0 : subMenuItem.children) === null || _b === void 0 ? void 0 : _b.map) === null || _c === void 0 ? void 0 : _c.call(_b, function (thirdMenuItem) {
                return (React.createElement("div", { key: thirdMenuItem.key, onClick: function () {
                        onSelect && onSelect([thirdMenuItem.key], thirdMenuItem);
                        setOpenKeys([]);
                    } },
                    React.createElement("div", { className: classNames({
                            'cn-ui-shell-full-header-sub-menu-item': true,
                            active: selectedKeys === thirdMenuItem.key ||
                                (selectedKeys === null || selectedKeys === void 0 ? void 0 : selectedKeys[0]) === thirdMenuItem.key ||
                                (selectedKeys === (thirdMenuItem === null || thirdMenuItem === void 0 ? void 0 : thirdMenuItem._topKey) &&
                                    thirdMenuItem.path === window.location.pathname),
                        }), key: thirdMenuItem.key },
                        React.createElement("div", null, thirdMenuItem.text),
                        React.createElement("div", { className: "cn-ui-shell-full-header-sub-menu-item-icon" }, fullMenuItemIconRender === null || fullMenuItemIconRender === void 0 ? void 0 :
                            fullMenuItemIconRender(thirdMenuItem),
                            hideExternalIcon ? null : (React.createElement(CnIcon, { className: "icon", type: "link-blank", onClick: function (e) {
                                    e.stopPropagation();
                                    onExternalSelect &&
                                        onExternalSelect([thirdMenuItem.key], thirdMenuItem);
                                    setOpenKeys([]);
                                } }))))));
            }))));
    })));
};
