import * as React from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import './full-header-menu.scss';
import { handleSetTopKey } from '../utils/full-header-menu';
export var Panel = function (innerProps) {
    var _a, _b;
    var menu = innerProps.menu, _dataSource = innerProps.dataSource, onSelect = innerProps.onSelect, setOpenKeys = innerProps.setOpenKeys, selectedKeys = innerProps.selectedKeys, fullMenuItemIconRender = innerProps.fullMenuItemIconRender, hideExternalIcon = innerProps.hideExternalIcon, onExternalSelect = innerProps.onExternalSelect, firstLevelActiveKey = innerProps.firstLevelActiveKey;
    // 把Lv1级的key，塞入所有子节点的属性中，兼容掉非法selectKeys不匹配当前path的场景
    var dataSource = React.useMemo(function () {
        return _dataSource.map(function (i) {
            var _a, _b, _c, _d;
            if (i.dataSource) {
                ((_a = i.dataSource) === null || _a === void 0 ? void 0 : _a.children) &&
                    ((_c = (_b = i.dataSource) === null || _b === void 0 ? void 0 : _b.children) === null || _c === void 0 ? void 0 : _c.length) &&
                    handleSetTopKey((_d = i.dataSource) === null || _d === void 0 ? void 0 : _d.children, i.key, i.key);
            }
            return i;
        });
    }, [_dataSource]);
    var firstLevelMap = React.useMemo(function () {
        var _a;
        return ((_a = dataSource === null || dataSource === void 0 ? void 0 : dataSource.reduce) === null || _a === void 0 ? void 0 : _a.call(dataSource, function (c, n) {
            if ((n === null || n === void 0 ? void 0 : n.key) && !c[n.key]) {
                c[n.key] = n;
            }
            return c;
        }, {})) || {};
    }, [dataSource]);
    var defaultFirstKey = React.useMemo(function () {
        var _key = null;
        dataSource === null || dataSource === void 0 ? void 0 : dataSource.some(function (i) {
            var _a;
            if ((_a = i === null || i === void 0 ? void 0 : i.dataSource) === null || _a === void 0 ? void 0 : _a.key) {
                _key = i.dataSource.key;
                return true;
            }
            return false;
        });
        return _key;
    }, [dataSource]);
    var _c = React.useState(''), moreFirstKey = _c[0], setMoreFirstKey = _c[1];
    React.useEffect(function () {
        if (firstLevelMap[firstLevelActiveKey] && !moreFirstKey) {
            setMoreFirstKey(firstLevelActiveKey);
        }
        else {
            setMoreFirstKey(moreFirstKey || defaultFirstKey);
        }
    }, [firstLevelActiveKey, moreFirstKey, defaultFirstKey, firstLevelMap]);
    var subItems = React.useMemo(function () {
        var _a, _b, _c;
        var items = (_a = firstLevelMap[moreFirstKey]) === null || _a === void 0 ? void 0 : _a.dataSource;
        return firstLevelMap[moreFirstKey]
            ? items === null || items === void 0 ? void 0 : items.children
            : (_c = (_b = dataSource[0]) === null || _b === void 0 ? void 0 : _b.dataSource) === null || _c === void 0 ? void 0 : _c.children;
    }, [dataSource, moreFirstKey, firstLevelMap]);
    var _offsetLeft = React.useMemo(function () {
        var header = document.querySelector('.header-menu');
        var left = (header === null || header === void 0 ? void 0 : header.offsetLeft) ? "".concat(header.offsetLeft, "px") : '20vw';
        return "calc(100vw - ".concat(left, ")");
    }, []);
    return (React.createElement("div", { className: "cn-ui-shell-full-header-menu-more", style: { width: _offsetLeft } },
        React.createElement("div", { className: "cn-ui-shell-full-header-menu-more-col scroll-y" }, (_a = dataSource === null || dataSource === void 0 ? void 0 : dataSource.map) === null || _a === void 0 ? void 0 : _a.call(dataSource, function (item) {
            var onFirstMenuEnter = function () {
                var _a;
                if (item.key === moreFirstKey)
                    return;
                var firstItem = menu === null || menu === void 0 ? void 0 : menu.find(function (i) { return i.key === item.key; });
                if ((firstItem === null || firstItem === void 0 ? void 0 : firstItem.path) && !((_a = firstItem === null || firstItem === void 0 ? void 0 : firstItem.children) === null || _a === void 0 ? void 0 : _a.length)) {
                    onSelect && onSelect([firstItem === null || firstItem === void 0 ? void 0 : firstItem.key], firstItem);
                }
                setMoreFirstKey(item.key);
            };
            return (React.createElement("div", { key: item.key, "data-key": item.key, onMouseEnter: onFirstMenuEnter, className: classNames({
                    'cn-ui-shell-full-header-menu-more-item-header': true,
                    'cn-ui-shell-full-header-menu-more-col-first-header': !!item.dataSource,
                    'cn-ui-shell-full-header-menu-more-col-first-header-hover': moreFirstKey !== item.key,
                    'cn-ui-shell-full-header-menu-more-col-first-header-active': moreFirstKey === item.key,
                }) },
                item.title,
                item.dataSource ? (React.createElement(CnIcon, { type: "arrow-right", size: "small" })) : null));
        })),
        React.createElement("div", { className: "cn-ui-shell-full-header-menu-more-sub scroll-x scroll-y" },
            React.createElement("div", { className: "sub-scroll-container" }, (_b = subItems === null || subItems === void 0 ? void 0 : subItems.map) === null || _b === void 0 ? void 0 : _b.call(subItems, function (item) {
                var _a, _b;
                return (React.createElement("div", { key: item.key, className: "cn-ui-shell-full-header-menu-more-col" },
                    React.createElement("div", { className: "cn-ui-shell-full-header-menu-more-item-header" }, item.text),
                    React.createElement("div", { className: "cn-ui-shell-full-header-menu-item-footer" }, (_b = (_a = item.children) === null || _a === void 0 ? void 0 : _a.map) === null || _b === void 0 ? void 0 : _b.call(_a, function (thirdMenuItem) {
                        var _a, _b;
                        return (React.createElement("div", { key: thirdMenuItem.key, onClick: function () {
                                onSelect &&
                                    onSelect([thirdMenuItem.key], thirdMenuItem);
                                setOpenKeys([]);
                            } },
                            React.createElement("div", { className: classNames({
                                    'cn-ui-shell-full-header-sub-menu-item': true,
                                    active: selectedKeys === (thirdMenuItem === null || thirdMenuItem === void 0 ? void 0 : thirdMenuItem.key) ||
                                        (selectedKeys === null || selectedKeys === void 0 ? void 0 : selectedKeys[0]) === (thirdMenuItem === null || thirdMenuItem === void 0 ? void 0 : thirdMenuItem.key) ||
                                        (selectedKeys === (thirdMenuItem === null || thirdMenuItem === void 0 ? void 0 : thirdMenuItem._topKey) &&
                                            ((_b = (_a = thirdMenuItem.path) === null || _a === void 0 ? void 0 : _a.split('?')) === null || _b === void 0 ? void 0 : _b[0]) ===
                                                window.location.pathname),
                                }), key: thirdMenuItem.key },
                                React.createElement("div", { className: "cn-ui-shell-full-header-sub-menu-item-title" }, thirdMenuItem.text),
                                React.createElement("div", { className: "cn-ui-shell-full-header-sub-menu-item-icon" }, fullMenuItemIconRender === null || fullMenuItemIconRender === void 0 ? void 0 :
                                    fullMenuItemIconRender(thirdMenuItem),
                                    hideExternalIcon ? null : (React.createElement(CnIcon, { className: "icon", type: "link-blank", onClick: function (e) {
                                            e.stopPropagation();
                                            onExternalSelect &&
                                                onExternalSelect([thirdMenuItem.key], thirdMenuItem);
                                            setOpenKeys([]);
                                        } }))))));
                    }))));
            })))));
};
