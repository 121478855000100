/**
 * 根据列表计算在栅格布局下占的span的值
 * @param column 列数
 * @returns span的值
 */
export function getSpan(column) {
    if (24 % column !== 0) {
        return "1p".concat(column);
    }
    return 24 / column;
}
