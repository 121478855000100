import React, { createContext, useState } from 'react';
import { configResponsive, useResponsive, useUpdateEffect } from 'ahooks';
import { useBreadcrumbDataSource } from './hooks/use-breadcrumb-data-source';
import { useLatest } from '../cn-scroll-bar/hooks';
import useSideBarExpandKeys from './hooks/use-side-bar-expand-keys';
export var ShellStateCtx = createContext({});
configResponsive({
    small: 0,
    middle: 1281,
    large: 1920,
});
export var ShellContext = function (props) {
    var children = props.children, shellProps = props.shellProps;
    var theme = shellProps.theme, headerVisible = shellProps.headerVisible, _a = shellProps.headerProps, headerProps = _a === void 0 ? {} : _a, sideBarVisible = shellProps.sideBarVisible, _b = shellProps.sideBarProps, sideBarProps = _b === void 0 ? {} : _b;
    var _c = sideBarProps.menu, menu = _c === void 0 ? [] : _c, defaultExpandAll = sideBarProps.defaultExpandAll, selectedMenuKey = sideBarProps.selectedMenuKey, menuCollapse = sideBarProps.menuCollapse, onToggleMenuCollapse = sideBarProps.onToggleMenuCollapse;
    var onToggleMenuCollapseRef = useLatest(onToggleMenuCollapse);
    var responsive = useResponsive();
    // 默认是否折叠
    var initIsSideBarFold = !responsive.middle;
    // 是否是受控模式(menuCollapse由外部控制)
    var isSideBarFoldControlled = 'menuCollapse' in sideBarProps;
    var _d = useState(isSideBarFoldControlled ? menuCollapse : initIsSideBarFold), isSideBarFold = _d[0], setIsSideBarFold = _d[1];
    var _e = useState(false), isSideBarPreview = _e[0], setIsSideBarPreview = _e[1];
    /** 通过响应式控制侧边栏展开和折叠逻辑 */
    useUpdateEffect(function () {
        var _a;
        // 受控模式收起情况下关闭侧边栏响应式
        if (menuCollapse === true)
            return;
        setIsSideBarFold(initIsSideBarFold);
        (_a = onToggleMenuCollapseRef.current) === null || _a === void 0 ? void 0 : _a.call(onToggleMenuCollapseRef, initIsSideBarFold);
    }, [initIsSideBarFold, onToggleMenuCollapseRef, menuCollapse]);
    useUpdateEffect(function () {
        setIsSideBarFold(menuCollapse);
    }, [menuCollapse]);
    useBreadcrumbDataSource({
        selectedKey: selectedMenuKey,
        sideBarMenu: menu,
        headerMenu: headerProps.menu,
    });
    var _f = useSideBarExpandKeys({
        defaultExpandAll: defaultExpandAll,
        selectedMenuKey: selectedMenuKey,
        menu: menu,
    }), sideBarExpandKeys = _f.sideBarExpandKeys, setSideBarExpandKeys = _f.setSideBarExpandKeys;
    return (React.createElement(ShellStateCtx.Provider, { value: {
            isSideBarPreview: isSideBarPreview,
            setIsSideBarPreview: setIsSideBarPreview,
            isSideBarFold: isSideBarFold,
            setIsSideBarFold: setIsSideBarFold,
            sideBarVisible: sideBarVisible,
            sideBarProps: sideBarProps,
            sideBarExpandKeys: sideBarExpandKeys,
            setSideBarExpandKeys: setSideBarExpandKeys,
            theme: theme,
            headerVisible: headerVisible,
            headerProps: headerProps,
        } }, children));
};
