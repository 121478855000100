import { __assign } from "tslib";
import { getSpan } from './get-span';
/**
 * 根据响应式配置计算栅格布局的属性
 * @returns 栅格布局的属性
 */
export function getColumnProps(grid) {
    var column = grid.column, m = grid.m, l = grid.l, s = grid.s, xl = grid.xl, xxs = grid.xxs, xs = grid.xs;
    var span = getSpan(column);
    var responseProps = { m: m, l: l, s: s, xl: xl, xxs: xxs, xs: xs };
    var columnProps = {};
    Object.keys(responseProps).forEach(function (key) {
        if (responseProps[key]) {
            columnProps[key] = getSpan(responseProps[key]);
        }
    });
    return __assign({ column: column, span: span }, columnProps);
}
