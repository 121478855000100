import $i18n from 'panda-i18n';
import * as React from 'react';
import classNames from 'classnames';
import { CnIcon } from '@/components/cn-icon';
import { MenuContainer, MenuItem, MenuPopupItem } from './menu';
import './full-header-menu.scss';
import { findFirstLevelActivatedByThirdLevelActiveKey } from '../utils/full-header-menu';
import { Panel } from './full-header-menu-panel';
import { SubPanel } from './full-header-menu-sub-panel';
export var FullHeaderMenu = function (props) {
    var _a;
    var menu = props.menu, onSelect = props.onSelect, hideExternalIcon = props.hideExternalIcon, onExternalSelect = props.onExternalSelect, selectedKeys = props.selectedKeys, fullMenuItemIconRender = props.fullMenuItemIconRender;
    var selectedKey = Array.isArray(selectedKeys)
        ? selectedKeys[0]
        : selectedKeys;
    var firstLevelActiveKey = React.useMemo(function () {
        return findFirstLevelActivatedByThirdLevelActiveKey(menu, selectedKey);
    }, [menu, selectedKey]);
    var _b = React.useState([]), openKeys = _b[0], setOpenKeys = _b[1];
    return (React.createElement(MenuContainer, { hozInLine: true, direction: "hoz", mode: "popup", className: "cn-ui-shell-full-header-menu-trigger", popupAutoWidth: true, triggerType: "hover", selectMode: "single", selectedKeys: selectedKeys, onSelect: function (keys, item, extra) {
            onSelect && onSelect(keys, item, extra);
        }, renderMore: function (more) {
            var _a;
            var newDs = more.map(function (item) {
                var _a, _b, _c;
                var data = (_b = (_a = item.props) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b.props;
                return {
                    key: item === null || item === void 0 ? void 0 : item.key,
                    title: (_c = item.props) === null || _c === void 0 ? void 0 : _c.title,
                    dataSource: data === null || data === void 0 ? void 0 : data.dataSource,
                };
            });
            return (React.createElement(MenuPopupItem, { noIcon: true, triggerType: 'hover', key: "0-more", label: React.createElement("div", { className: classNames({
                        more: true,
                        'cn-ui-shell-full-header-menu-trigger-item': true,
                        active: (_a = newDs === null || newDs === void 0 ? void 0 : newDs.some) === null || _a === void 0 ? void 0 : _a.call(newDs, function (item) { return item.key === firstLevelActiveKey; }),
                    }) },
                    React.createElement("div", { className: "cn-ui-shell-full-header-menu-trigger-item-header" }, $i18n.get({ id: 'More', dm: '更多', ns: 'CnShell' }))) },
                React.createElement(Panel, { dataSource: newDs, onSelect: onSelect, setOpenKeys: setOpenKeys, selectedKeys: selectedKeys, fullMenuItemIconRender: fullMenuItemIconRender, hideExternalIcon: hideExternalIcon, onExternalSelect: onExternalSelect, firstLevelActiveKey: firstLevelActiveKey })));
        }, openKeys: openKeys, onOpen: setOpenKeys }, (_a = menu === null || menu === void 0 ? void 0 : menu.map) === null || _a === void 0 ? void 0 : _a.call(menu, function (menuItem) {
        var _a;
        if (((_a = menuItem === null || menuItem === void 0 ? void 0 : menuItem.children) === null || _a === void 0 ? void 0 : _a.length) > 0) {
            return (React.createElement(MenuPopupItem, { key: menuItem.key, noIcon: true, "data-full-menu-item-key": menuItem.key, triggerType: 'hover', title: menuItem.text, label: React.createElement("div", { key: menuItem.key, className: classNames({
                        'cn-ui-shell-full-header-menu-trigger-item': true,
                        active: firstLevelActiveKey === menuItem.key,
                    }) },
                    React.createElement("div", { className: "cn-ui-shell-full-header-menu-trigger-item-header" }, menuItem.text),
                    React.createElement("div", { className: "cn-ui-shell-full-header-menu-trigger-item-footer" },
                        React.createElement(CnIcon, { type: "arrow-down", size: "small" }))) },
                React.createElement(SubPanel, { dataSource: menuItem, onSelect: onSelect, setOpenKeys: setOpenKeys, firstLevelActiveKey: firstLevelActiveKey, selectedKeys: selectedKey, fullMenuItemIconRender: fullMenuItemIconRender, hideExternalIcon: hideExternalIcon, onExternalSelect: onExternalSelect })));
        }
        return (React.createElement(MenuItem, { key: menuItem.key, "data-full-menu-item-key": menuItem.key, title: menuItem.text, onClick: function () {
                onSelect && onSelect([menuItem.key], menuItem);
            } },
            React.createElement("div", { key: menuItem.key, className: classNames({
                    'cn-ui-shell-full-header-menu-trigger-item': true,
                    active: firstLevelActiveKey === menuItem.key,
                }) },
                React.createElement("div", { className: "cn-ui-shell-full-header-menu-trigger-item-header" }, menuItem.text))));
    })));
};
